import { store } from "@/services/store";
import axios from "axios";
import { CompetitorExecOrderRuleEnum } from "@/Constants";
import { RST_LEVEL, RST_CATEGORY, RST_COMPETITOR, RST_EVENT, RST_JUDGE_EVENT_NOTES, RST_JUDGE_EVENT_COMPULSORY_NOTE } from "@/LocalDB";
import { RST_COMPETITION, RST_ROUND, RST_ROUND_COMPULSORY, RST_COMPETITOR_COMPOSITION, RST_COMPETITION_STAFF, SEASON_COMPETITION_TYPE_CATEGORY_LEVEL /*RST_COMPETITOR_NOTES, RST_COMPETITOR_COMPULSORY_NOTE*/} from "@/LocalDB";
import { REF_EVENT, RST_REF_EVENT, RST_EVENT_STAFF, RST_EVENT_COMPETITOR, RST_EVENT_COMPULSORY } from "@/LocalDB";
//import { RST_LOG } from "@/LocalDB";
import SynchroHelper from "@/services/helpers/synchroHelper";
import { v4 as uuid} from 'uuid';
import { RST_ROUND_COMPETITOR } from "../../LocalDB";
import CompetitionsConfigurationHelper from "./competitionsConfigurationHelper";

const RoundsConfigurationHelper = {

  missingRequirementsBeforeRun(round){
    var ret = CompetitionsConfigurationHelper.missingRequirementsBeforeRun(round.competition_id);

    if(round.round_number == null)
      ret.push("Le numéro de la manche n'est pas précisé");


    if(round.date == null || round.date == ''){
      ret.push("La date de la manche n'a pas été indiquée.")
    }

    if(round.chiefJudge_staff_id == null || round.chiefJudge_staff_id == ''){
      ret.push("Aucun juge n'a été désigné comme chef juge pour la manche.");
    }

    if(round.competitors_order_rule == null || round.competitors_order_rule == ''){
      ret.push("La règle à utiliser pouur calculer l'odre de passage des compétieurs n'a pas été déterminée.");
    }
/*
    if(round.rule == null || round.rule ==''){
      ret.push("Le format à appliquer à l'ensemble des catégories pour la manqhe n'a pas été choisi.")
    } else if (round.RULE.hasCompulsories) {

      var nbMissing = round.RULE.numberOfCompulsories - round.COMPULSORIES
    }


    var rule = event.CATEGORY.RULE;
    var nbMissing = ret.length;
    var eventRule = rule.EVENTS.filter(item => item.event == event.event)[0];
    console.log('missingRequirementsBeforeRun (event)', rule, eventRule);
    if(eventRule.minJudgesRequiredToCompeteEvent > 0)
    {
      nbMissing = eventRule.minJudgesRequiredToCompeteEvent - event.JUDGES.filter(item => !item.isShadow ).length;
      if(nbMissing > 0)
        ret.push((nbMissing > 1)
                    ? "Il manque " + nbMissing + " juges titulaires"
                    : "Il manque un juge titulaire");
    }
    if(eventRule.minTechJudgesRequiredToCompeteEvent > 0)
    {
      nbMissing = eventRule.minTechJudgesRequiredToCompeteEvent - event.TECH_JUDGES.filter(item => !item.isShadow ).length;
      if(nbMissing > 0)
        ret.push((nbMissing > 1)
                  ? "Il manque " + nbMissing + " juges techniques titulaires"
                  : "Il manque un juge technique titulaire");
    }
    if(eventRule.minArtJudgesRequiredToCompeteEvent > 0)
    {
      nbMissing = eventRule.minArtJudgesRequiredToCompeteEvent - event.ART_JUDGES.filter(item => !item.isShadow ).length;
      if(nbMissing > 0)
        ret.push((nbMissing > 1)
                  ? "Il manque " + nbMissing + " juges artistiques titulaires"
                  : "Il manque un juge artistique titulaire");
    }
    if(eventRule.minFieldDirectorsRequiredToCompeteEvent > 0)
    {
      nbMissing = eventRule.minFieldDirectorsRequiredToCompeteEvent - event.FIELD_DIRECTORS.filter(item => !item.isShadow ).length;
      if(nbMissing > 0)
        ret.push((nbMissing > 1)
                  ? "Il manque " + nbMissing + " directeurs de terrain titulaires"
                  : "Il manque un directeur de terrain titulaire");
    }
    if(eventRule.minScorersRequiredToCompeteEvent > 0)
    {
      nbMissing = eventRule.minScorersRequiredToCompeteEvent - event.SCORERS.filter(item => !item.isShadow ).length;
      if(nbMissing > 0)
        ret.push((nbMissing > 1)
                  ? "Il manque " + nbMissing + " scoreurs titulaires"
                  : "Il manque un scoreur titulaire");
    }

    if(event.EVENT.hasCompulsories) {
      nbMissing = rule.numberOfCompulsories - event.CATEGORY.COMPULSORIES.length;
      if(nbMissing > 0)
        ret.push("La sélection de figures imposées n'est pas complète. Il en manque " + nbMissing);
      else if (nbMissing < 0)
        ret.push((rule.numberOfCompulsories > 1)
            ? "Seules " + rule.numberOfCompulsories + " figures imposées peuvent être choisies. Il y en a " + nbMissing + " de trop"
            : "Une seule figure imposée doit être choisie. Il y en a " + nbMissing + " de trop");
    }

    if(event.COMPETITORS.length == 0)
    {
      ret.push("Il n'y a aucun compétiteur d'inscrit dans cette catégorie");
    } else if (event.COMPETITORS.filter(item => !item.isForfeited && !item.isAbsent ).length == 0){
      ret.push((event.COMPETITORS.length > 1)
                  ? 'Tous les compétiteurs inscrits dans cette catégorie sont soit absents soit "forfait"'
                  : 'Le seul compétiteur inscrit dans cette catégorie est soit absent soit "forfait"');
    }
    */
    return ret;
  },

  warningsBeforeRun(round)
  {
    var ret = [];
    console.log('warniingBeforeRun', round);

    //console.log("RoundsConfigurationHelper.werningsBeforRun", event);
/*    var nbMissing = 0;

    var rule = event.CATEGORY.RULE;
    if(rule.minJudgesRequiredToSkipWorseNote > 0)
    {
      nbMissing = rule.minJudgesRequiredToSkipWorseNote - event.JUDGES.filter(item => !item.isShadow ).length;
      if(nbMissing > 0)
        ret.push(((nbMissing > 1)
                    ? "Il manque " + nbMissing + " juges titulaires"
                    : "Il manque un juge titulaire")
                    + " pour pouvoir appliquer la règle de retrait de la plus mauvaise note");
    }
    if(rule.minJudgesRequiredToSlkipBestNote > 0)
    {
      nbMissing = rule.minJudgesRequiredToSlkipBestNote - event.JUDGES.filter(item => !item.isShadow ).length;
      if(nbMissing > 0)
        ret.push(((nbMissing > 1)
                    ? "Il manque " + nbMissing + " juges titulaires"
                    : "Il manque un juge titulaire")
                    + " pour pouvoir appliquer la règle de retrait de la note la plus forte");
    }*/
    return ret;
  },

  compare(a, b, direction = 'asc'){
    var d = (direction == 'desc') ? -1 : 1;
    if (a > b) return d * 1;
    if (a < b) return d * -1;
    return 0;
  },
  getRandomInt(max){
    return Math.floor(Math.random() * max);
  },
  getRandomArray(max){
    var ret = [];
    while(ret.length < max){
      var test = this.getRandomInt(max);
      if(ret.indexOf(test) < 0)
        ret.push(test);
    }
    return ret.map(i => i+1);
  },
/*  sortCompetitorsForExecution(competitors, excutionOrderRule){
    var ret = competitors;

    if(ret != null && ret.length > 0 && excutionOrderRule != null && excutionOrderRule != CompetitorExecOrderRuleEnum.Subscription )
    {
      switch(excutionOrderRule)
      {
        case CompetitorExecOrderRuleEnum.Alea:
          ret.sort((a, b) => a.random_order - b.random_order);
          console.log(ret);
          break;
        case CompetitorExecOrderRuleEnum.Alpha:
          ret.sort((a,b) => this.compare(a.COMPETITOR.name, b.COMPETITOR.name, 'asc'));
          break;
        case CompetitorExecOrderRuleEnum.AlphaReverse:
          ret.sort((a,b) => this.compare(a.COMPETITOR.name, b.COMPETITOR.name, 'desc'));
          break;
        case CompetitorExecOrderRuleEnum.PrevRoundReverse:
          console.log ('tri des compétiteurs par ordre "' + CompetitorExecOrderRuleEnum. PrevRoundReverse + "' à implémenter");
          break;
        case CompetitorExecOrderRuleEnum.Rank:
          console.log ('tri des compétiteurs par ordre "' + CompetitorExecOrderRuleEnum. Rank + "' à implémenter");
          break;
        case CompetitorExecOrderRuleEnum.RankReverse:
          console.log ('tri des compétiteurs par ordre "' + CompetitorExecOrderRuleEnum. RankReverse + "' à implémenter");
          break;
      }
    }
    var o = 1;
    return ret.map(c => { c.predicted_order = o++; return c; });

  },*/
  sortCompetitorsForExecution(competitors, round, level, category){
//    console.log('sortCompetitorsForExecution', competitors, round, level, category);
    var ret = competitors;
    var excutionOrderRule = round.competitors_order_rule;
    if(ret != null && ret.length > 0 && excutionOrderRule != null && excutionOrderRule != CompetitorExecOrderRuleEnum.Subscription )
    {
      switch(excutionOrderRule)
      {
        case CompetitorExecOrderRuleEnum.Alea:
          ret.sort((a, b) => a.random_order - b.random_order);
          break;
        case CompetitorExecOrderRuleEnum.Alpha:
          ret.sort((a,b) => this.compare(a.COMPETITOR.name, b.COMPETITOR.name, 'asc'));
          break;
        case CompetitorExecOrderRuleEnum.AlphaReverse:
          ret.sort((a,b) => this.compare(a.COMPETITOR.name, b.COMPETITOR.name, 'desc'));
          break;
        case CompetitorExecOrderRuleEnum.PrevRoundReverse:
          var roundCompetitors = RST_ROUND_COMPETITOR.query().where('competition_id', round.competition_id).where('round_number', round.round_number).where('level', level).where('category', category).get();
          ret = ret.map(r => {
            var rc = roundCompetitors.find(s => s.competitor_id == r.competitor_id);
            console.log({ searched: r, found : rc});
            r.predicted_order = (rc != null) ? rc.predicted_order : (r.predicted_order || 9999);
            return r;
          });
          ret.sort((a,b) => a.predicted_order - b.predicted_order);
          console.log ('tri des compétiteurs par ordre "' + CompetitorExecOrderRuleEnum. PrevRoundReverse, ret);
          break;
        case CompetitorExecOrderRuleEnum.Rank:
          console.log ('tri des compétiteurs par ordre "' + CompetitorExecOrderRuleEnum. Rank + "' à implémenter");
          break;
        case CompetitorExecOrderRuleEnum.RankReverse:
          console.log ('tri des compétiteurs par ordre "' + CompetitorExecOrderRuleEnum. RankReverse + "' à implémenter");
          break;
      }
    }else if(ret != null){
      // Tri par défaut = ordre d'inscription
      ret.sort((a,b) => a.subscription_order - b.subscription_order);
    }
    var result = ret.map((c,i) => { c.predicted_order = i; c.order = i; return c; });
//    console.log('result', result);
    return result;
  },

  ensureLevelsHavingCompetitors(round){
    var ret = RST_COMPETITOR.query()
                .where("competition_id", round.competition_id)
                //.where("round_number", round.round_number)
                .get()
                .map(item => item.level)
                .filter((value, index, self) => { return self.indexOf(value) == index; });
    ret.forEach(level => {
      var db = RST_LEVEL.query()
                .where("competition_id", round.competition_id)
                .where("round_number", round.round_number)
                .where("level", level)
                .first();
      if(db == null){
        db = { competition_id: round.competition_id, round_number: round.round_number, level: level};
        RST_LEVEL.insertOrUpdate({ data: db});
      }
    });
    ret = RST_LEVEL.query().where("competition_id", round.competition_id).where("round_number", round.round_number).get();
    return ret;
  },
  ensureLevelsHavingEffectiveCompetitors(round){
    var ret = RST_COMPETITOR.query()
                .where("competition_id", round.competition_id)
                //.where("round_number", round.round_number)
                .where("isWithdrawn", false)
                .where("isForfeited", false)
                .where("isAbsent", false)
                .get()
                .map(item => item.level)
                .filter((value, index, self) => { return self.indexOf(value) == index; });
    ret.forEach(level => {
      var db = RST_LEVEL.query()
                .where("competition_id", round.competition_id)
                .where("round_number", round.round_number)
                .where("level", level)
                .first();
      if(db == null){
        db = { competition_id: round.competition_id, round_number: round.round_number, level: level};
        RST_LEVEL.insertOrUpdate({ data: db});
      }
    });
    ret = RST_LEVEL.query().where("competition_id", round.competition_id).where("round_number", round.round_number).get();
    return ret;
  },

  ensureCategoriesHavingCompetitors(level){
    var ret = RST_COMPETITOR.query()
                .where("competition_id", level.competition_id)
                .where("level", level.level)
                .get()
                .map(item => item.category)
                .filter((value, index, self) => { return self.indexOf(value) === index})

    ret.forEach(category => {
      var db = RST_CATEGORY.query()
                .where("competition_id", level.competition_id)
                .where("round_number", level.round_number)
                .where("level", level.level)
                .where("category", category)
                .first();
      if(db == null){
        db = { competition_id: level.competition_id, round_number: level.round_number, level: level.level, category: category};
        RST_CATEGORY.insertOrUpdate({ data: db});
      }
    });
    ret = RST_CATEGORY.query().where("competition_id", level.competition_id).where("round_number", level.round_number).where("level", level.level).get();
    return ret;
  },
  ensureCategoriesHavingEffectiveCompetitors(level){
    var ret = RST_COMPETITOR.query()
                .where("competition_id", level.competition_id)
                .where("level", level.level)
                .where("isWithdrawn", false)
                .where("isForfeited", false)
                .where("isAbsent", false)
                .get()
                .map(item => item.category)
                .filter((value, index, self) => { return self.indexOf(value) === index})

    ret.forEach(category => {
      var db = RST_CATEGORY.query()
                .where("competition_id", level.competition_id)
                .where("round_number", level.round_number)
                .where("level", level.level)
                .where("category", category)
                .first();
      if(db == null){
        db = { competition_id: level.competition_id, round_number: level.round_number, level: level.level, category: category};
        RST_CATEGORY.insert({ data: db});
        SynchroHelper.LogOperation("INSERT", "RST_CATEGORY", null, db);
      }
    });
    ret = RST_CATEGORY.query()
      .where("competition_id", level.competition_id)
      .where("round_number", level.round_number)
      .where("level", level.level)
      .where(cat => { return cat.EFFECTIVE_COMPETITORS.length > 0})
      .get();
    return ret;
  },
  getPilotsDelegateCandidates(Round)
  {
    var check = RST_COMPETITOR_COMPOSITION.all();
    console.log("TeamMembers", check);
    var ret = Round.COMPETITORS
                .filter(competitor => competitor.COMPETITOR.isIndividual)
                .filter(competitor => {
                  return !(competitor.isWithdrawn || competitor.isForfeited || competitor.isAbsent)
                })
                .map(competitor => competitor.COMPETITOR.PEOPLE);
    Round.COMPETITORS
                .filter(competitor => !competitor.COMPETITOR.isIndividual)
                .filter(competitor => {
                  return !(competitor.isWithdrawn || competitor.isForfeited || competitor.isAbsent)
                })
                .forEach(competitor => {
                  competitor.MEMBERS.forEach(teamMember => {
                    ret.push(teamMember.PEOPLE);
                  });
                });
    ret = [...new Map(ret.map(item => [item["id"], item])).values()];
    ret.sort((a,b) => {return (a.name < b.name) ? -1 : ((a.name > b.name) ? 1 : 0); });
    return ret;
  },
  isCategoryStarted(competition_id, level_code, category_code) {
    return RST_EVENT.query()
              .where("competition_id", competition_id)
              .where("level", level_code)
              .where("category", category_code)
              .where("isStarted", true)
              .exists();
  },

  createRound(competitionId)
  {
    var competition = RST_COMPETITION.query().where("id", competitionId).first();
    console.log('createRound -> competition', competition);
    var newNumber = competition.ROUNDS.length + 1;
    var newRound = {
      id: uuid(),
      competition_id: competitionId,
      round_number: newNumber,
      isSync: false
    };
    RST_ROUND.insertOrUpdate({ data: newRound });
    SynchroHelper.LogOperation("INSERT", "RST_ROUND", null, newRound);
    SynchroHelper.TryToSynchronize();
    return RST_ROUND.query().where("competition_id", competitionId).where("round_number", newNumber).first();
  },
  async createRoundAsync(competition_id){
    var operationsIds = [];
    var competition = RST_COMPETITION.query().where("id", competition_id).first();
    console.log('createRoundAsync -> competition', competition);
    var newNumber = competition.ROUNDS.length + 1;
    var newRound = {
      id: uuid(),
      competition_id: competition_id,
      round_number: newNumber,
      isSync: false
    };
    RST_ROUND.insertOrUpdate({ data: newRound });
    operationsIds.push(SynchroHelper.LogOperation("INSERT", "RST_ROUND", null, newRound));

    var success = await SynchroHelper.TryToSynchronizeAsync(operationsIds);

    return { success: success, round: RST_ROUND.query().where("competition_id", competition_id).where("round_number", newNumber).first()};    
  },
  TOCHECK_deleteRound(competitionId)
  {
    var firstDeletable = RST_ROUND.query()
                          .where("competition_id", competitionId )
                          .where("isStarted", false)
                          .where("isBriefingDone", false)
                          .orderBy('round_number', 'desc')
                          .first();
    if(firstDeletable == null)
    {
      var withNotes = RST_JUDGE_EVENT_NOTES.query()
                        .where("competition_id", competitionId)
                        .where(notes => { return notes.compulsories_isCompleted
                                            || notes.routine_isCompleted
                                            || notes.ballet_isCompleted
                              })
                        .get()
                        .map(notes => notes.round_number)
                        .filter((value, index, array) => { return array.indexOf(value) == index });
      var withCompulsories = RST_JUDGE_EVENT_COMPULSORY_NOTE.query()
                        .where("competition_id", competitionId)
                        .where("isCompleted", true)
                        .get()
                        .map(note => note.round_number)
                        .filter((value, index, array) => { return array.indexOf(value) == index });
      var allNonDeletable = withNotes.concat(withCompulsories);
      firstDeletable = RST_ROUND.query()
                        .where("competition_id", competitionId)
                        .where(round => allNonDeletable.indexOf(round.round_number) == -1)
                        .orderBy("round_number", "desc")
                        .first();
    }
    if(firstDeletable != null)
    {
      RST_COMPETITION_STAFF.query().where('competition_id', competitionId).where('round_number', firstDeletable.round_number).get().forEach(item => {
        SynchroHelper.LogOperation("DELETE", "RST_ROUND_STAFF", item.id, null);
        RST_COMPETITION_STAFF.delete(item.id);
      })
      RST_COMPETITOR_COMPOSITION.query().where('competition_id', competitionId).where('round_number', firstDeletable.round_number).get().forEach(item => {
        SynchroHelper.LogOperation("DELETE", "RST_COMPETITOR_COMPOSITION", item.id, null);
        RST_COMPETITOR_COMPOSITION.delete(item.id);
      })
      RST_COMPETITOR.query().where('competition_id', competitionId).where('round_number', firstDeletable.round_number).get().forEach(item => {
        SynchroHelper.LogOperation("DELETE", "RST_COMPETITOR", item.id, null);
        RST_COMPETITOR.delete(item.id);
      })
      RST_EVENT.query().where('competition_id', competitionId).where('round_number', firstDeletable.round_number).get().forEach(item => {
        SynchroHelper.LogOperation("DELETE", "RST_EVENT", item.id, null);
        RST_EVENT.delete(item.id);
      });
      RST_CATEGORY.query().where('competition_id', competitionId).where('round_number', firstDeletable.round_number).get().forEach(item => {
        SynchroHelper.LogOperation("DELETE", "RST_CATEGORY", item.id, null);
        RST_CATEGORY.delete(item.id);
      });
      RST_LEVEL.query().where('competition_id', competitionId).where('round_number', firstDeletable.round_number).get().forEach(item => {
        SynchroHelper.LogOperation("DELETE", "RST_LEVEL", item.id, null);
        RST_LEVEL.delete(item.id);
      });
      RST_ROUND_COMPULSORY.query().where('competition_id', competitionId).where('round_number', firstDeletable.round_numer).get().forEach(item => {
        SynchroHelper.LogOperation("DELETE", "RST_ROUND_COMPULSORY", item.id, null);
        RST_ROUND_COMPULSORY.delete(item.id);
      });

      SynchroHelper.LogOperation("DELETE", "RST_ROUND", firstDeletable.id, null );
      RST_ROUND.delete(firstDeletable.id);

      SynchroHelper.TryToSynchronize();
      return true;
    }
    return false;
  },
  async deleteRoundAsync(competition_id, round_number){
    //var operationIds = [];
    console.log('deleteRoundAsync NOT IMPLEMENTED', competition_id, round_number);
    return false;
  },
  createLevel(competitionId, round_number, level_code){
    var newid = uuid();
    var newLevel = {
      id: newid,
      competition_id: competitionId,
      round_number: round_number,
      level: level_code,
      isSync: false,
    };
    RST_LEVEL.insertOrUpdate({ data: newLevel });
    SynchroHelper.LogOperation("INSERT", "RST_LEVEL", null, newLevel);
    SynchroHelper.TryToSynchronize();
    return RST_LEVEL.query().where("competition_id", competitionId).where("round_number", round_number).where("level", level_code).first();
  },

  createCategory(competitionId, round_number, level_code, category_code)
  {
    var newid = uuid();
    var newCategory = {
      id: newid,
      competition_id: competitionId,
      round_number: round_number,
      level: level_code,
      category: category_code,
      isSync: false,
    };
     RST_CATEGORY.insertOrUpdate({ data: newCategory });
     SynchroHelper.LogOperation("INSERT", "RST_CATEGORY", null, newCategory);
     SynchroHelper.TryToSynchronize();
     return RST_CATEGORY.query().where("competition_id", competitionId).where("round_number", round_number).where("level", level_code).where("category", category_code).first();
  },

  changeDate(competitionId, round_number, date)
  {
    var round = RST_ROUND.query().where("competition_id", competitionId).where("round_number", round_number).first();
    var data = { date: date};
    RST_ROUND.update({ where: round.id, data: data});
    SynchroHelper.LogOperation("UPDATE", "RST_ROUND", round.id, data, "CHANGE_DATE");
    SynchroHelper.TryToSynchronize();
  },
  changeExecutionOrderRule(competitionId, round_number, order_rule)
  {
    var round = RST_ROUND.query().where("competition_id", competitionId).where("round_number", round_number).first();
    var data = { competitors_order_rule: order_rule};
    RST_ROUND.update({ where: round.id, data: data});
    SynchroHelper.LogOperation("UPDATE", "RST_ROUND", round.id, data, "CHANGE_EXEC_ORDER_RULE");
    SynchroHelper.TryToSynchronize();
  },
  async changeExecutionOrderRuleAsync(competitionId, round_number, order_rule)
  {
    var round = RST_ROUND.query().where("competition_id", competitionId).where("round_number", round_number).first();
    var data = { competitors_order_rule: order_rule};
    RST_ROUND.update({ where: round.id, data: data});
    var operationId = SynchroHelper.LogOperation("UPDATE", "RST_ROUND", round.id, data, "CHANGE_EXEC_ORDER_RULE");
    /*round.COMPETITION.LEVELS.forEach(l => {
      l.CATEGORIES.forEach(c => {
        var round_competitors = round._COMPETITORS.where("level", l.level).where('category', c.category);
      });
    });*/
    return await SynchroHelper.TryToSynchronizeAsync([operationId]);
  },
  async createRefEventAsync(competition_id, round_number, event_code, level_code, forceCreate = false){
    var round = RST_ROUND.query().where("competition_id", competition_id).where("round_number", round_number).first();
    if(round != null){
      if(round.isCompleted)
        throw("Impossible d'ajouter cette épreuve car la manche est terminée");
      if(round.isStarted && !forceCreate)
        throw("Impossible d'ajouter cette épreuve car la manche est commencée");
      if(round.isBriefingCompleted && !forceCreate)
        throw("Impossible d'ajouter cette épreuve car le briefing de la manche a été réalisé");
      if(round.isBriefingStarted && !forceCreate)
        throw("Impossible d'ajouter cette épreuve car le briefing de la manche est commencé");
      if((event_code == 'precision' || event_code == 'ballet') && round._RST_REF_EVENTS.where('level', level_code).where('event', 'mix').exists() )
        throw("Impossible d'ajouter cette épreuve car elle est incompatible avec l'épreuve mixte déjà ajoutée");
      if(event_code == 'mix' && round._RST_REF_EVENTS.where('level', level_code).where('event', 'precision').exists())
        throw("Impossible d'ajouter cette épreuve car elle est incompatible avec l'épreuve de précision déjà ajoutée");
      if(event_code == 'mix' && round._RST_REF_EVENTS.where('level', level_code).where('event', 'ballet').exists())
        throw("Impossible d'ajouter cette épreuve car elle est incompatible avec l'épreuve de ballet déjà ajoutée");
    
      var operationsIds = [];
      var dataIns = {
        id: uuid(),
        competition_id: competition_id,
        round_number: round_number,
        level: level_code,
        event: event_code,
        isBriefingStarted: false,
        isBriefingCompleted: false,
        isStarted: false,
        isCompleted: false,
        displayOrder: REF_EVENT.query().where('code', event_code).first().displayOrder
      };
      RST_REF_EVENT.insertOrUpdate({ data : dataIns });
      operationsIds.push(SynchroHelper.LogOperation("INSERT", "RST_REF_EVENT", null, dataIns));
      return await SynchroHelper.TryToSynchronizeAsync(operationsIds);
    } else
      throw ("Impossible de retrouver la manche correspondant aux critères");
  },
  async deleteRefEventAsync(competition_id, round_number, event_code, level_code, forceDelete = false){
    var sameEvent = RST_REF_EVENT.query().where("competition_id", competition_id).where("round_number", round_number).where("level", level_code).where("event", event_code).first();
    var evt = sameEvent.RST_EVENT;
    if(sameEvent != null){
      if(sameEvent.isCompleted || (evt != null && evt.isCompeted))
        throw("Impossible de supprimer cette épreuve car elle est terminée");
      if((sameEvent.isStarted || (evt != null && evt.isStarted)) && !forceDelete)
        throw("Impossible de supprimer cette épreuve car elle est commencée");
      if(sameEvent.ROUND.isBriefingCompleted && !forceDelete)
        throw("Impossible de supprimer cette épreuve car le briefing de la manche a été réalisé");
      if(sameEvent.ROUND.isBriefingStarted && !forceDelete)
        throw("Impossible de supprimer cette épreuve car le briefing de la manche est commencé");

      var operationsIds = [];
      if(sameEvent.RST_EVENT != null){
        sameEvent.RST_EVENT.STAFF_MEMBERS.forEach(m => {
          RST_EVENT_STAFF.delete(m.id);
          operationsIds.push(SynchroHelper.LogOperation("DELETE", "RST_EVENT_STAFF", m.id, null));
        })
        sameEvent.RST_EVENT.COMPULSORIES.forEach(c => {
          RST_EVENT_COMPULSORY.delete(c.id);
          operationsIds.push(SynchroHelper.LogOperation("DELETE", "RST_EVENT_COMPULSORY", c.id, null))
        })
        sameEvent.RST_EVENT.COMPETITORS.forEach(c => {
          RST_EVENT_COMPETITOR.delete(c.id);
          operationsIds.push(SynchroHelper.LogOperation("DELETE", "RST_EVENT_COMPETITOR", c.id, null))
        })
        RST_EVENT.delete(sameEvent.RST_EVENT.id);
        operationsIds.push(SynchroHelper.LogOperation("DELETE", "RST_EVENT", sameEvent.RST_EVENT.id, null))
      }
      RST_REF_EVENT.delete(sameEvent.id);
      operationsIds.push(SynchroHelper.LogOperation("DELETE", "RST_REF_EVENT", sameEvent.id, null));
      
      return await SynchroHelper.TryToSynchronizeAsync(operationsIds);
    } else {
      throw ("Impossible de retrouver l'épreuve correspondant aux critères");
    }
  },
  changeApplySkipWorseNoteRule(competitionId, round_number, newValue)
  {
    var round = RST_ROUND.query().where("competition_id", competitionId).where("round_number", round_number).first();
    var data = { applySkipWorseNoteRule: newValue};
    RST_ROUND.update({ where: round.id, data: data});
    SynchroHelper.LogOperation("UPDATE", "RST_ROUND", round.id, data, "CHANGE_SKIP_WORSE_NOTE");
    SynchroHelper.TryToSynchronize();
  },
  async changeApplySkipWorseNoteRuleAsync(competitionId, round_number, newValue)
  {
    var round = RST_ROUND.query().where("competition_id", competitionId).where("round_number", round_number).first();
    var data = { applySkipWorseNoteRule: newValue};
    RST_ROUND.update({ where: round.id, data: data});
    var operationId = SynchroHelper.LogOperation("UPDATE", "RST_ROUND", round.id, data, "CHANGE_SKIP_WORSE_NOTE");
    return await SynchroHelper.TryToSynchronizeAsync([operationId]);
  },
  changeApplySkipBestNoteRule(competitionId, round_number, newValue)
  {
    var round = RST_ROUND.query().where("competition_id", competitionId).where("round_number", round_number).first();
    var data = { applySkipBestNoteRule: newValue};
    RST_ROUND.update({ where: round.id, data: data});
    SynchroHelper.LogOperation("UPDATE", "RST_ROUND", round.id, data, "CHANGE_SKIP_BEST_NOTE");
    SynchroHelper.TryToSynchronize();
  },
  async changeApplySkipBestNoteRuleAsync(competitionId, round_number, newValue)
  {
    var round = RST_ROUND.query().where("competition_id", competitionId).where("round_number", round_number).first();
    var data = { applySkipBestNoteRule: newValue};
    RST_ROUND.update({ where: round.id, data: data});
    var operationId = SynchroHelper.LogOperation("UPDATE", "RST_ROUND", round.id, data, "CHANGE_SKIP_BEST_NOTE");
    return await SynchroHelper.TryToSynchronizeAsync([operationId]);
  },
  changeRuleForLevel(competitionId, round_number, level_code, newValue){
    var level = RST_LEVEL.query().where("competition_id", competitionId).where("round_number", round_number).where("level", level_code).first();
    var data = { rule: newValue};
    RST_LEVEL.update({ where: level.id, data: data});
    SynchroHelper.LogOperation("UPDATE", "RST_LEVEL", level.id, data, "CHANGE_RULE");

    SEASON_COMPETITION_TYPE_CATEGORY_LEVEL.query()
      .where("year", level.COMPETITION.year)
      .where("competition_type", level.COMPETITION.competition_type)
      .where("level", level.code)
      .get()
      .forEach(item => {
        var category = RST_CATEGORY.query()
          .where("competition_id", competitionId)
          .where("round_number", round_number)
          .where("level", level_code)
          .where("category", item.category)
          .first();
        RST_CATEGORY.update({ where: category.id, data: data });
        SynchroHelper.LogOperation("UPDATE", "RST_CATEGORY", category.id, data, "CHANGE_RULE");
      });

    SynchroHelper.TryToSynchronize();
  },
  changeRuleForLevelAndCategory(competitionId, round_number, level_code, category_code, newValue){
    var category = RST_LEVEL.query().where("competition_id", competitionId).where("round_number", round_number).where("level", level_code).where("category", category_code).first();
    var data = { rule: newValue};
    RST_CATEGORY.update({ where: category.id, data: data});
    SynchroHelper.LogOperation("UPDATE", "RST_CATEGORY", category.id, data, "CHANGE_RULE");
    SynchroHelper.TryToSynchronize();
  },
  async changeRoundChiefJudgeAsync(competitionId, round_number, staff_member_id)
  {
    var found = RST_ROUND.query().where("competition_id", competitionId).where("round_number", round_number).first();
    if(found != null){
      var data = { chiefJudge_staff_id: staff_member_id };
      RST_ROUND.update({ where: found.id, data: data });
      var operationId = SynchroHelper.LogOperation("UPDATE", "RST_ROUND", found.id, data, "CHANGE_CHIEF_JUDGE");
      return await SynchroHelper.TryToSynchronizeAsync([operationId]);
    } else
      throw "Manche inconnue";
  },
  /*
  async refreshRoundStatusesAsync(competitionId, round_number){
    var baseUrl = store.getters["config/serverBaseUrl"];
    var url = baseUrl + '/api/competitions/getRoundStatuses.php';
    try{
      const response = await axios.post(url, { competition_id: competitionId, round_number: round_number });
      if(this.VERBOSE) console.log('refreshRoundStatusesAsync =>', response);
      if(response.data && response.data.round){
        store.commit("synchro/setServerNotResponding", false);
        store.commit('synchro/setNetAvailability', true);

        var alreadySync = RST_ROUND.query().where("competition_id", competitionId).where("round_number", round_number).where("isSync", true).get();
        alreadySync.forEach(item => { RST_ROUND.delete(item.id)});
        RST_ROUND.insertOrUpdate({ data: response.data.round });

        if(response.data.events){
          alreadySync = RST_EVENT.query().where("competition_id", competitionId).where("round_number", round_number).where("isSync", true).get();
          alreadySync.forEach(item => { RST_EVENT.delete(item.id)});
          RST_EVENT.insertOrUpdate({ data: response.data.events });
        }
        return true;
      }
      return false;
    }
    catch(error){
      if(this.VERBOSE) console.error('refreshRoundStatusesAsync => error : ', error);
      store.commit("synchro/setServerNotResponding", true);
      store.commit('synchro/setNetAvailability', false);

      return false;
    }  
  },*/
  async refreshRoundsStatusesAsync(competitionId){
    var baseUrl = store.getters["config/serverBaseUrl"];
    var url = baseUrl + '/api/competitions/getRoundsStatuses.php';
    try{
      const response = await axios.post(url, { competition_id: competitionId });
      //if(this.VERBOSE) console.log('refreshRoundStatusesAsync =>', response);
      if(response.data && response.data.rounds){
        store.commit("synchro/setServerNotResponding", false);
        store.commit('synchro/setNetAvailability', true);

        var alreadySync = RST_ROUND.query().where("competition_id", competitionId).where("isSync", true).get();
        alreadySync.forEach(item => { RST_ROUND.delete(item.id)});
        RST_ROUND.insertOrUpdate({ data: response.data.rounds });

        if(response.data.events){
          alreadySync = RST_EVENT.query().where("competition_id", competitionId).where("isSync", true).get();
          alreadySync.forEach(item => { RST_EVENT.delete(item.id)});
          RST_EVENT.insertOrUpdate({ data: response.data.events });
        }
        return true;
      }
      return false;
    }
    catch(error){
      //if(this.VERBOSE) console.error('refreshRoundStatusesAsync => error : ', error);
      store.commit("synchro/setServerNotResponding", true);
      store.commit('synchro/setNetAvailability', false);

      return false;
    }  
  }
}

export default RoundsConfigurationHelper;
